
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { useMemo } from 'react';
import useSWR from 'swr';
import type { CuratedContentQuery, LandingPageQuery } from 'generated/graphql';
import ContainerGrid from 'src/components/container-grid';
import ContentContainerBanner from 'src/components/content-container-banner';
import { FaqList } from 'src/components/faq-list';
import HeadTags from 'src/components/head-tags';
import LandingPageExclusiveContent from 'src/components/landing-page/landing-page-exclusive-content';
import LandingPageFooter from 'src/components/landing-page/landing-page-footer';
import LandingPageHero2022 from 'src/components/landing-page/landing-page-hero-2022';
import LandingPageLive from 'src/components/landing-page/landing-page-live';
import MarketingDevices from 'src/components/marketing/marketing-devices';
import Page from 'src/components/page';
import { SeoFooter } from 'src/components/seo-footer';
import SubscriptionPlans from 'src/components/subscription-plans';
import useGeoInfo from 'src/hooks/use-geo-info';
import useSdk from 'src/hooks/use-sdk';
import useTranslate from 'src/hooks/use-translate';
import { TrackingContext, TrackingContextValues } from 'src/tracking/tracking-context';
import { EmptyPageParameters, LandingPageLiveConcert, LandingPageNewAlbums, LandingPagePopularNow } from 'src/types';
import { isContentAvailable } from 'src/utilities/content-availability-helpers';
import { Faq, getFaqs } from 'src/utilities/get-faqs';
import { getCurrentLocale } from 'src/utilities/i18n-helpers';
import { serializeTranslationsToSeoSections, PagePropsWithSeo } from 'src/utilities/seo-helpers';
import { incrementalServerSideWithQuery } from 'src/utilities/ssr-helpers';
// Width of an album cover in a slider or desktop
const sliderAlbumWidth = 160;
// Gap between album covers in a slider on desktop
const sliderAlbumGap = 30;
// Number of items we request for server side rendering
const sliderItemCountServerSide = 11;
// Returns number of items we need to fetch to cover the current screen
const getSliderItemCount = () => typeof window === 'undefined'
    ? undefined
    : Math.max(Math.ceil(window?.screen.width / (sliderAlbumWidth + sliderAlbumGap)), sliderItemCountServerSide);
type LandingPageProps = {
    fallbackData: LandingPageQuery;
    faqs: Faq[];
} & PagePropsWithSeo;
/**
 * Landing page showing the main features of the app. This page is only shown to non-authenticated users.
 */
function LandingPage({ fallbackData, faqs, seoSections }: LandingPageProps) {
    const t = useTranslate();
    const sdk = useSdk();
    const { data: geoInfoData } = useGeoInfo();
    // Fetch the landing page data from the API, but only as much as we need for the current screen
    const { data } = useSWR('landing-page', () => sdk.landingPage({ sliderItemsFirst: getSliderItemCount() }), {
        fallbackData
    });
    // Select the banner
    const banner = useMemo(() => {
        const bannerNode = data.curatedContent.curatedContent.find((contentContainer) => contentContainer.__typename === 'Banner');
        // If the banner is not found, return null, otherwise confirm the type so TypeScript knows it's a Banner
        return bannerNode && bannerNode.__typename === 'Banner' ? bannerNode : null;
    }, [data?.curatedContent.curatedContent]);
    // Select the "Next Live Concerts" by item type "Live Concert"
    const liveConcerts = useMemo(() => {
        const liveConcertsSlider = data.curatedContent.curatedContent.find((container): container is Extract<CuratedContentQuery['curatedContent']['curatedContent'][number], {
            __typename: 'ListSlider';
        }> => {
            return container.__typename === 'ListSlider' && container.items[0].__typename === 'LiveConcert';
        });
        return (liveConcertsSlider?.items || []).filter((item): item is LandingPageLiveConcert => item.__typename === 'LiveConcert' && isContentAvailable(item, { countryCode: geoInfoData?.countryCode }));
    }, [data?.curatedContent.curatedContent, geoInfoData?.countryCode]);
    // Select the "Popular Now" slider by item type "VODConcert"
    const popularNowItems = useMemo(() => {
        const slider = data.curatedContent.curatedContent.find((container) => {
            return container.__typename === 'ListSlider' && container.items[0].__typename === 'VodConcert';
        });
        return slider && slider.__typename === 'ListSlider' ? (slider.items as LandingPagePopularNow[]) : null;
    }, [data?.curatedContent.curatedContent]);
    // Select the "New Albums" slider by item type "Album"
    const newAlbumsItems = useMemo(() => {
        const slider = data.curatedContent.curatedContent.find((container) => {
            return container.__typename === 'ListSlider' && container.items[0].__typename === 'Album';
        });
        return slider && slider.__typename === 'ListSlider' ? (slider.items as LandingPageNewAlbums[]) : null;
    }, [data?.curatedContent.curatedContent]);
    const subscriptionPlans = data?.subscriptionPlansStripe;
    return (<Page testId="landing-page">
      <HeadTags title={t('page__landing_title')} description={t('page__landing_description')}/>

      {/* Default Landing page Hero with a video */}
      <LandingPageHero2022 />

      {/* Optional Banner */}
      {banner && <ContentContainerBanner banner={banner}/>}

      {/* Live Module */}
      {liveConcerts && <LandingPageLive liveConcerts={liveConcerts} className="pb-20 pt-12"/>}

      {/* Exclusive Content */}
      {popularNowItems && newAlbumsItems ? (<LandingPageExclusiveContent popularNowItems={popularNowItems} newAlbumItems={newAlbumsItems}/>) : null}

      {/* Ticket Module */}
      {subscriptionPlans && (<div className="pb-24 pt-12 2xl:pb-32">
          <TrackingContext.Provider value={TrackingContextValues.LandingPageSubscriptionPlans}>
            <SubscriptionPlans subscriptionPlans={subscriptionPlans.plans} titleTranslationKey="page__landing_subscribe"/>
          </TrackingContext.Provider>
        </div>)}

      {/* Devices Module */}
      <MarketingDevices />

      {/* FAQs */}
      {faqs.length > 0 && (<>
          <h2 className="dg-text-medium-1 mb-14 text-center md:mb-12">{t('marketing_devices__faqs')}</h2>
          <ContainerGrid>
            <div className="col-span-full pb-16 md:pb-20 2xl:col-span-8 2xl:col-start-3">
              <FaqList faqs={faqs}/>
            </div>
          </ContainerGrid>
        </>)}
      <SeoFooter sections={seoSections}/>
    </Page>);
}
// the landing page footer is a separate component
LandingPage.footerComponent = LandingPageFooter;
export default LandingPage;
const getStaticProps = incrementalServerSideWithQuery<EmptyPageParameters, LandingPageProps>(async ({ context, sdk }) => {
    const fallbackData = await sdk.landingPage();
    const currentLocale = getCurrentLocale(context.locale);
    const faqs = await getFaqs({ locale: currentLocale });
    // Fetch SEO articles to show in the footer
    const seoSections = await serializeTranslationsToSeoSections([
        'landing__seo_section_1_content',
        'landing__seo_section_2_content',
        'landing__seo_section_3_content',
        'landing__seo_section_4_content',
        'landing__seo_section_5_content',
        'landing__seo_section_6_content',
        'landing__seo_section_7_content',
        'landing__seo_section_8_content',
        'landing__seo_section_9_content',
        'landing__seo_section_10_content',
        'landing__seo_section_11_content',
        'landing__seo_section_12_content',
        'landing__seo_section_13_content',
        'landing__seo_section_14_content',
        'landing__seo_section_15_content',
        'landing__seo_section_16_content',
        'landing__seo_section_17_content',
        'landing__seo_section_18_content',
        'landing__seo_section_19_content',
        'landing__seo_section_20_content',
        'landing__seo_section_21_content',
        'landing__seo_section_22_content',
        'landing__seo_section_23_content',
        'landing__seo_section_24_content',
        'landing__seo_section_25_content',
    ], context);
    return {
        props: { fallbackData, faqs, seoSections },
        revalidate: 3600
    };
});

    async function __Next_Translate__getStaticProps__19505189768__(ctx) {
      const res = await getStaticProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__19505189768__ as getStaticProps }
  